#honeybadger-feedback-wrapper #honeybadger-feedback {
    background-color: rgb(249, 250, 251);
    color: rgb(33, 43, 54);
}

#honeybadger-feedback-wrapper #honeybadger-feedback #honeybadger-feedback-footer {
    display: none !important;
}

/* #honeybadger-feedback-wrapper #honeybadger-feedback #honeybadger-feedback-form p:nth-of-type(1), */
#honeybadger-feedback-wrapper #honeybadger-feedback #honeybadger-feedback-form p:nth-of-type(2) {
    display: none;
}

#honeybadger-feedback-wrapper #honeybadger-feedback #honeybadger-feedback-form #honeybadger-feedback-comment {
    margin-top: 5px;
    height: 100px;
    border-radius: 4px;
    font-size: 16px;
}

#honeybadger-feedback-wrapper #honeybadger-feedback #honeybadger-feedback-form #honeybadger-feedback-submit {
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-font-smoothing: antialiased;
    -webkit-user-select: none;
    align-items: center;
    appearance: none;
    background-color: rgb(80, 174, 133);
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-style: none;
    border-bottom-width: 0;
    border-image-repeat: stretch;
    border-image-source: none;
    border-left-color: rgb(255, 255, 255);
    border-left-style: none;
    border-left-width: 0;
    border-right-color: rgb(255, 255, 255);
    border-right-style: none;
    border-right-width: 0;
    border-top-color: rgb(255, 255, 255);
    border-radius: 8px;
    border-top-style: none;
    border-top-width: 0;
    box-shadow: rgba(80, 174, 133, 0.24) 0 8px 16px 0;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: inline-flex;
    font-family: "Public Sans", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-variant-caps: normal;
    font-weight: 700;
    height: 48px;
    justify-content: center;
    letter-spacing: normal;
    line-height: 26px;
    margin: 0;
    min-width: 64px;
    outline: rgb(255, 255, 255) none 0;
    padding: 8px 22px;
    position: relative;
    text-align: center;
    text-decoration-color: rgb(255, 255, 255);
    text-decoration-line: none;
    text-decoration-style: solid;
    text-indent: 0;
    text-shadow: none;
    text-transform: uppercase;
    transition-delay: 0s, 0s, 0s, 0s;
    transition-duration: 0.25s, 0.25s, 0.25s, 0.25s;
    transition-property: background-color, box-shadow, border-color, color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1);
    vertical-align: middle;
    word-spacing: 0;
    writing-mode: horizontal-tb;
}
